import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

const Seo = ({
  description,
  lang = 'en',
  meta = [],
  keywords = [],
  title,
  path,
  imageUrl,
}) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        const image = getSrc(data.file.childImageSharp.gatsbyImageData);
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: `${data.site.siteMetadata.siteUrl}${path}`,
              },
              {
                property: 'og:image',
                content: imageUrl
                  ? `https:${imageUrl}`
                  : `${data.site.siteMetadata.siteUrl}${image}`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.title,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                property: 'twitter:image',
                content: imageUrl
                  ? `https:${imageUrl}`
                  : `${data.site.siteMetadata.siteUrl}${image}`,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
};

export default Seo;

const detailsQuery = graphql`
  query DefaultSeoQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }
    file(relativePath: { regex: "/cover.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
